<template>
  <div id="withdraw_record">
    <div class="inDev">
      <img src="@/assets/image/PersonalCenter/FinancialCenter/inDev.png" alt="">
      <div class="inDev_tip">该功能正在开发中，敬请期待！</div>
    </div>
<!--    <div class="filter">-->
<!--      <div class="select_ground">-->
<!--        <div class="withdrawal_date">-->
<!--          <el-select v-model="value" :popper-append-to-body="false" clearable placeholder="提现日期" @change="selectDateChange">-->
<!--            <el-option-->
<!--              v-for="item in options"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </div>-->
<!--        <div class="withdrawal_state">-->
<!--          <el-select v-model="statusValue" :popper-append-to-body="false" clearable placeholder="提现状态" @change="selectStateChange">-->
<!--            <el-option-->
<!--              v-for="item in statusOptions"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="detail_list">-->
<!--      <el-table-->
<!--        :data="tableData"-->
<!--        style="width: 100%">-->
<!--        &lt;!&ndash; 空数据 &ndash;&gt;-->
<!--        <template slot="empty">-->
<!--          <div class="no_data">-->
<!--            <img src="@/assets/image/PersonalCenter/Seller/noData.png" alt="">-->
<!--          </div>-->
<!--        </template>-->
<!--        <el-table-column-->
<!--          type="index"-->
<!--          width="30">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.$index+1}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 提现账户 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="提现账户"-->
<!--          width="131">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.row.account}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->

<!--        &lt;!&ndash; 提现金额 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="提现金额"-->
<!--          width="70">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.row.price}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 服务费 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="服务费"-->
<!--          width="60">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.row.rateAmount}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 实际到账金额 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="实际到账金额"-->
<!--          width="100">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.row.actualAmount}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 提现时间 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="提现时间">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.row.add_time|moments}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 拒绝原因 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="拒绝原因"-->
<!--          width="90">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="fail_msg">{{scope.row.fail_msg}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 状态 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="状态"-->
<!--          width="110">-->
<!--          <template slot-scope="scope">-->
<!--            <div slot="reference" class="name-wrapper">-->
<!--              <el-tag v-if="scope.row.status =='-1'" type="danger" size="medium">未通过</el-tag>-->
<!--              <el-tag v-else-if="scope.row.status =='0'" type="primary" size="medium">审核中</el-tag>-->
<!--              <el-tag v-else-if="scope.row.status =='1'" type="success" size="medium">已提现</el-tag>-->
<!--              <el-tag v-else type="danger" size="medium">撤回申请</el-tag>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 操作 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="操作"-->
<!--          width="100">-->
<!--          <template slot-scope="scope">-->
<!--            <div v-if="scope.row.status =='0'" class="btn_ground">-->
<!--              <div class="thumbs_up" @click="changeCashStatus(scope.row)" >撤回</div>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--      <div class="pages" v-if="totalCount !== 0">-->
<!--        <el-pagination-->
<!--          @current-change="handleCurrentChange"-->
<!--          :current-page.sync="currentPage"-->
<!--          :page-size="PageSize"-->
<!--          layout="prev, pager, next, jumper"-->
<!--          :total="totalCount">-->
<!--        </el-pagination>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
import {apiUserCashList,apiUserCashStatus} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      inDev:0,
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:0,
      // 默认每页显示的条数（可修改）
      PageSize:10,
      //表格数据
      tableData: [],
      //提现日期下拉框
      options: [
        {value: '0', label: '全部'},
        {value: '1', label: '本周内'},
        {value: '2', label: '当月'},
        {value: '3', label: '三个月内'},
        {value: '4', label: '半年内'},
        {value: '5', label: '半年以上'},
        {value: '6', label: '一年内'},
      ],
      value: '',
      //提现状态下拉框
      statusOptions: [
        {value: '1', label: '提现成功'},
        {value: '0', label: '待审核'},
        {value: '-1', label: '审核失败'},
        {value: '2', label: '已撤销审核'},
      ],
      statusValue: '',
      //分页页码
      page:1
    }
  },
  methods: {
    //计算开发倒计时天数
    getDevDays(dateString1,dateString2){
      var startDate = dateString1;
      var endDate = Date.parse(dateString2);
      if (startDate>endDate){
        return 0;
      }
      if (startDate==endDate){
        return 1;
      }
      var days=(endDate - startDate)/(1*24*60*60*1000);
      return  parseInt(days);
    },
    //table监听事件
    handleEdit(index, row) {
    },
    handleDelete(index, row) {
    },
    handleCurrentChange(val) {
      this.getData(this.value,this.statusValue,val)
    },
    //提现日期 下拉框 监听事件
    selectDateChange(event){
      this.getData(this.value,this.statusValue,this.page)
    },
    //提现状态 下拉框 监听事件
    selectStateChange(event){
      this.getData(this.value,this.statusValue,this.page)
    },
    /**
     * 获取 提现记录 数据
     * @param type    提现日期
     * @param status  提现状态
     * @param page    分页页码
     */
    getData(type,status,page){
      apiUserCashList({
        type:type,
        status:status,
        page:page,
      }).then(res=>{
        this.tableData = res.data.list
        this.totalCount = res.data.total
        this.currentPage = res.data.page
      })
    },
    /**
     * 撤回 提现
     * @param data 行数据
     */
    changeCashStatus(data){
      apiUserCashStatus({
        id:data.id,
        status:2
      }).then(res=>{
        this.$message({
          center:true,
          type:"success",
          message:"撤回提现成功"
        })
        this.onLoad()
      }).catch(err=>{
        this.$message({
          center:true,
          type:"error",
          message:err.msg
        })
      })
    },
    //初始化
    onLoad(){
      apiUserCashList({
      }).then(res=>{
        this.tableData = res.data.list
        this.totalCount = res.data.total
        this.currentPage = res.data.page
      })
    }
  },
  mounted () {
    //初始化
    this.onLoad()
  },
  watch: {

  },
  computed: {

  }
}
</script>

<style lang='less' scoped>
#withdraw_record{
  //display: flex;
  //flex-direction: column;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  justify-content: center;
  .inDev_tip{
    font-size: 14px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #9A9A9A;
    line-height: 20px;
    span{
      color: #FCAD13;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
  }
  .filter{
    margin: 27px 0 43px 0;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select_ground{
      display: flex;
      flex-direction: row;
      /deep/.el-input__inner{
        width: 180px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
      }
      /deep/.el-input.is-focus .el-input__inner{
        border: 1px solid #FCAD13;
      }
      /deep/.popper__arrow{
        left: 155px !important;
      }
      /deep/.el-select-dropdown__item.selected{
        color: #606266;
        font-weight: 400;
      }
      .withdrawal_date{
        margin-right: 22px;
      }
      .withdrawal_state{

      }
    }

  }
  .detail_list{
    /deep/.el-table th{
      height: 50px;
      background: #F4F4F4;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
    }
    /deep/.el-table th>.cell{
      text-align: center;
    }
    /deep/.el-table .cell{
      padding: 0;
      text-align: center;
    }
    /deep/.el-table td, .el-table th{
      padding: 0;
    }
    /deep/.el-table td{
      height: 104px;
    }
    //状态
    /deep/.el-tag{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      background: #D8F1FC;
      color: #16559D;
    }
    /deep/.el-tag.el-tag--danger{
      padding: 0 2px;
      background: #FCD8D8;
      color: #B90101;
    }
    /deep/.el-tag.el-tag--info{
      background: #F0F0F0;
      color: #BFBFBF;
    }
    /deep/.el-tag.el-tag--success{
      background: #D8FCDE;
      color: #389D16;
    }
    /deep/.el-tag.el-tag--warning{
      background: #FFF2D9;
      color: #FCAD13;
    }

    //id、提现账户、提现金额、服务费、实际到账金额、提现时间
    .list_text{
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    .fail_msg{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      font-size: 12px;
      line-height: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      text-align: left !important;
    }
    //操作
    .btn_ground{
      display: flex;
      flex-direction: column;
      div{
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #000000;
        border-radius: 20px;
      }
      .thumbs_up{
        background: #FFDB51;
        margin-bottom: 4px;
      }
      .ask_service{
        border: 1px solid #000000;
      }
    }
    //分页
    .pages{
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    //暂无数据
    .no_data{
      padding: 36px;
      img{
        width: 196px;
        height: 151px;
        object-fit: cover;
      }
    }
  }
}
</style>
